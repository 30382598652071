/**
 * Copyright © 2020 Redbox Digital Limited. All rights reserved.
 */

define('Magento_Theme/js/theme',[
    'jquery',
    'matchMedia',
    'Magento_Theme/js/model/scroll-shadow-mutation-observer',
    'domReady!'
], function ($, mediaCheck, scrollShadowMutationObserver) {
    'use strict';

    if(!$('body').hasClass('carehub-assisted')) {
        $('.header.links li:first-child a').addClass('my-account')
    }

    $('#chat-action').on('click', function () {
        $(this).parent().toggleClass('active')
    })

    $.fn.maxlength = function (length) {
        return this.on('keydown', function (e) {
            var maxlength = length || parseInt($(this).attr('maxlength'), 10);
            var keyCode = (e.keyCode ? e.keyCode : e.which);

            // Array: backspace, enter, arrows
            var functionKeysPressed = [8, 13, 37, 38, 40].indexOf(e.which) > -1;

            // Prevent Shift Key usage (+), - and . (Input type number allows +, - and .)
            // Prevent above max length limit but allow above keys array
            if ((e.shiftKey || keyCode === 189 || keyCode === 190) || (maxlength && $(this).val().length >= maxlength && !keyCode === functionKeysPressed)) {
                e.preventDefault();
            }
        });
    };

    if ('loading' in HTMLImageElement.prototype) {
        const images = document.querySelectorAll('img[loading="lazy"]');
        images.forEach(img => {
            img.src = img.dataset.src;
        });
    } else {
        const script = document.createElement('script');
        script.src = window.lazysizesJs;
        document.body.appendChild(script);
    }

    if ($('.catalog-product-view .column.main .result-promotion-top-line').find('.label').length) {
        $('.catalog-product-view .column.main .product.attribute.manufacturer').addClass('has-offer-label')
    }

    mediaCheck({
        media: '(min-width: 1008px)',

        /**
         * Switch to Desktop Version.
         */
        entry: function () {
            $('.column.main > .page-title-wrapper.product').detach().prependTo('.product-info-main')
            $('.column.main > .product.attribute.manufacturer').detach().prependTo('.product-info-main')
            $('.product-info-main .promo-items').detach().appendTo('.product.media.bottom')
            $('body.account .page.messages').detach().insertBefore('body.account #maincontent .columns')

            if ($('.checkout-cart-index').length && !$('.cart-empty').length) {
                $('.checkout-methods-items').insertBefore('.block.collapse.discount')
                $('.courier').detach().prependTo('.cart-summary')

                if ($('.delivery-counter-wrapper').length) {
                    $('.delivery-counter-wrapper').detach().prependTo('.cart-summary')
                }

                $('.checkout-methods-items').detach().prependTo('.cart-summary')
                $('.block.collapse.discount').detach().appendTo('.cart-container')

                if ($('.family-perk-item').length) {
                    $('.family-perk-item').detach().appendTo('.cart-container')
                }
            }

            if ($('.link-head').length) {
                $('.link-head').off('click')
            }

            if ($('.header.links').length) {
                if(!$('body').hasClass('carehub-assisted')) {
                    $('.my-account').prependTo('.header.links li:first-child');
                }
                $('.algolia-search-actions').insertAfter('#algoliaAutocomplete');
                $('.algolia-search-block, .algolia-search-actions').removeClass('show');
                $('#algolia-glass, .algolia-close').off('click')
            }

            $(document).on("click", ".aa-SubmitButton" , function(e) {
                e.preventDefault();
                $('.header .aa-Form').trigger('submit');
            });
        },

        /**
         * Switch to Mobile Version.
         */
        exit: function () {
            $('.page-title-wrapper.product').detach().prependTo('.column.main')
            $('.product.attribute.manufacturer').detach().prependTo('.column.main')
            $('.product.media.bottom .promo-items').detach().insertAfter('.view-phone-details')
            $('.product-info-main > .cc-savings').detach().insertBefore('.promo-items')
            $('body.account #maincontent .page.messages').detach().insertBefore('body.account #maincontent')

            if($('.breadcrumbs .items').length > 0) {
                scrollShadowMutationObserver.setShadow('.breadcrumbs', '.breadcrumbs .items', '.breadcrumbs .items li')
            } else {
                scrollShadowMutationObserver.initiate('.breadcrumbs', '.breadcrumbs .items', '.breadcrumbs .items li', function () {})
            }

            if ($('.checkout-cart-index').length && !$('.cart-empty').length) {
                $('.block.collapse.discount').insertAfter('.courier')
                $('.checkout-methods-items').detach().appendTo('.cart-summary')
                $('.courier').detach().appendTo('.cart-summary')

                if ($('.delivery-counter-wrapper').length) {
                    $('.delivery-counter-wrapper').detach().appendTo('.cart-summary')
                }

                if ($('.family-perk-item').length) {
                    $('.family-perk-item').detach().insertBefore('.checkout-methods-items')
                }
            }

            if ($('.link-head').length) {
                $('.link-head').on('click', function () {
                    $(this).parent().toggleClass('show')
                })
            }

            if ($('.header.links').length) {
                if(!$('body').hasClass('carehub-assisted')) {
                    $('.my-account').insertBefore('.minicart-wrapper')
                }
                $('.algolia-search-actions').insertBefore('.minicart-wrapper')
                $('#algolia-glass, .algolia-close').on('click', function () {
                    $('.algolia-search-block, .algolia-search-actions').toggleClass('show')
                })
            }

        }
    });
});

